import axios from '@axios'

export default function modulesServices() {
  const getModules = async (queryParams) => {
    const res = await axios.get('/modules', {
      params: queryParams,
    })
    return res
  }

  const create = async (queryParams) => {
    const res = await axios.post('/module/create', queryParams)
    console.log(res.data)
    return res
  }

  const edit = async (queryParams) => {
    const res = await axios.post('/module/update', queryParams)
    return res
  }

  const del = async (queryParams) => {
    const res = await axios.post('/module/delete', queryParams)
    return res
  }

  return {
    getModules,
    create,
    edit,
    del,
  }
}
